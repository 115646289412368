

















































































































































































import { Component, Vue } from "vue-property-decorator";
import { OrganizationService,WechatService, UserService, PackageService, OrderService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS } from "src/enums";
import { EnumUtils, tools } from "src/utils";
import { Service } from "src/decorator";
declare let WeixinJSBridge: any 
declare let document: any

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component
export default class SubmitOrder extends Vue
{
    /**
     * 是否选择日期
     * @private
     * @returns boolean
     */
    private showDate: boolean = false;

    /**
     * 显示确认弹框
     * @private
     * @returns boolean
     */
    private showDialog: boolean = false;

    /**
     * 固定套餐项目
     * @private
     * @returns any
     */ 
    private showlookdetail: boolean = false;

    /**
     * 固定套餐项目
     * @private
     * @returns any
     */
    private baseItemTypesOut: any = null;

    /**
     * 加项套餐项目
     * @private
     * @returns any
     */
    private optionalItemTypesOut: any = null;

    /**
     * 套餐信息
     * @private
     * @returns any
     */
    private packageOut: any =
    {
        packageId: "",
        packageCode: ""
    };

    /**
     * 机构信息
     * @private
     * @returns any
     */
    private hospitalOut: any =
    {
        hospitalId: "",
        hospitalCode: ""
    };

    /**
     * 固定套餐项目选中
     * @private
     * @returns any
     */
    private fixedCollapseActive: any = [];

    /**
     * 自选项目选中
     * @private
     * @returns any
     */
    private collapseActiveNames: any = [];

    /**
     * 是否在加载中
     * @private
     * @returns boolean
     */
    private loading: boolean = false;


    /**
     * 套餐详情
     * @private
     * @returns any
     */
    private packageInfo: any = {};

    /**
     * 选择日期
     * @private
     * @returns any
     */
    private selectOrderDate: string = "";

    /**
     * 机构id
     * @private
     * @returns string
     */
    private get hospitalId(): string
    {
        return this.$route.query && (this.$route.query.hospitalId as string) || "";
    }

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }

    /**
     * 获取固定套餐项目个数
     * @private
     * 
     * @returns number
     */
    private get fixedNum(): number
    {
        let sum = 0;

        this.baseItemTypesOut && this.baseItemTypesOut.forEach((item)=>
        {
            sum += item.items && item.items.length || 0
            
        })

        return sum;
    }

    /**
     * 需要支付价格
     * @private
     * @returns number
     */
    private get payPrice(): string
    {
        // 权益直接返回加项
        if(this.serviceItemId)
        {
            return this.optionalPrice;
        }
        else
        {
            return (+this.fixedPrice + (+this.optionalPrice)).toFixed(2);
        }
    }
    
    /**
     * 获取固定套餐价格
     * @private
     * @returns number
     */
    private get fixedPrice(): string
    {
        let sumPrice = 0;
        if(this.baseItemTypesOut && this.baseItemTypesOut.length>0)
        {
            this.baseItemTypesOut.forEach((item)=>
            {
                item.items.forEach(d=>
                {
                    sumPrice+=d.itemPrice;
                })
            })
        }
        return sumPrice.toFixed(2);
    }

    /**
     * 自选金额
     * @private
     * @returns strig
     */
    private get optionalPrice(): string
    {
        let selectItemPrice = [];
        if(this.optionalItemTypesOut && this.optionalItemTypesOut.length > 0)
        {
            this.optionalItemTypesOut.forEach(item => {

                item.items.forEach(data =>
                {
                    if(this.selectOptionalItem.indexOf(data.itemId) > -1)
                    {
                        selectItemPrice.push(data.itemPrice);
                    }

                });
            });
        }
        
        // 计算总金额
        let sum = selectItemPrice.reduce((prve: number,curr: number) =>
        {
            return prve + curr;
        }, 0);

        return sum.toFixed(2) || 0.00;
    }
    
    /**
     * 选中加项id
     * @private
     * @returns Array<number | string>
     */
    private get selectOptionalItem(): Array<number | string>
    {
       let selectOptionalItemStorage = localStorage.getItem("selectOptionalItem");

       if(selectOptionalItemStorage)
       {
           return JSON.parse(selectOptionalItemStorage) || [];
       }

       return [];
    }

    /**
     * 获取选中的id加项
     * @private
     * @returns string
     */
    private get optionalItemArr(): any
    {
        let optionalItemArr = [];
        if(this.optionalItemTypesOut && this.optionalItemTypesOut.length > 0)
        {
            this.optionalItemTypesOut.forEach(item => {
                let obj = Object.assign({},{
                    ...item,
                    data:[]
                })

                item.items.forEach(data =>
                {
                    if(this.selectOptionalItem.indexOf(data.itemId) > -1)
                    {
                        obj.data.push(data);
                    }
                });
                optionalItemArr.push(obj);
            });
        }
        return optionalItemArr;
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location || {};
    }

    /**
     * 获取套餐详情
     * @private
     * @returns void
     */
    private async getPackageDetail(data: any): Promise<void>
    {
        try
        {
            let {content: result} =  await PackageService.instance.getPackageInfo(data);

            if(result.data)
            {
                this.baseItemTypesOut = result.data.baseItemTypesOut;
                this.optionalItemTypesOut = result.data.optionalItemTypesOut;
                this.packageOut = result.data.packageOut;
                this.hospitalOut = result.data.hospitalOut;
            }

        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 套餐id
     * @private
     * @returns string
     */
    private get packageId(): string
    {
        return this.$route.query && (this.$route.query.packageId as string) || "";
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get selectUserId(): any
    {
        let selectUserStorage = localStorage.getItem("selectUser");
        
        if(selectUserStorage)
        {
            return JSON.parse(selectUserStorage).id || this.userInfo.userId;
        }
        
        return this.userInfo.userId;
    }

    /**
     * 获取当前选择人信息
     * @private
     * @returns void
     */
    private get selectUserName(): any
    {
        let selectUserStorage = localStorage.getItem("selectUser");

        if(selectUserStorage)
        {
            return JSON.parse(selectUserStorage).memberName || this.userInfo.userName;
        }
        
        return this.userInfo.userName;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        let data =
        {
            hospitalId: null,
            packageId: null,
            cityName: null,
            longitude: null,
            latitude: null,
            memberId: null
        }
        if(this.hospitalId)
        {
            data.hospitalId = this.hospitalId;
        }

        data.packageId = this.packageId;
        data.cityName = this.location && this.location.city;
        data.longitude = this.location && this.location.lng;
        data.latitude = this.location && this.location.lat;
        data.memberId = this.selectUserId;
        if(this.$route.query && this.$route.query.date)
        {
            this.selectOrderDate = dayjs(this.$route.query.date as string).format("YYYY/MM/DD");
        }
        

        this.getPackageDetail(data);
    }
    /**
     * 提交表单
     * @private
     * @returns void
     */
    private onSubmit()
    {
        if(!this.selectOrderDate)
        {
            Toast("请选择日期");
        }
        else
        {
            this.showDialog = true;
        }
    }

    /**
     * 跳转地图
     * @private
     * @returns void
     */
    private async openMap(hospitalOut: any): Promise<any>
    {
        if(hospitalOut)
        {
            let lat = hospitalOut.latitude;
            let lng =hospitalOut.longitude;;
            let address = hospitalOut.address;
            let orgName = hospitalOut.hospitalName;

            if(tools.isWechat())
            {
                let currentUrl = location.href;
                let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
                if(result.data)
                {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: result.data.appId, // 必填，公众号的唯一标识
                        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                        signature: result.data.signature, // 必填，签名
                        jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
                    });

                    wx.ready(function()
                    {
                        wx.openLocation({
                            latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
                            longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
                            scale: 14, // 缩放比例
                            name: orgName,
                            address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: ""
                        });
                    });
                }
            }
            else
            {
                // 高德
                let href = `http://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
                window.open(href);
            }

        }

    }

    /**
     * 日期确认选择
     * @private
     * @returns string
     */
    private onDateConfirm(date: any): void
    {
        if(date)
        {
            this.selectOrderDate = dayjs(date).format("YYYY/MM/DD");
        }
        
        this.showDate = false;
    }

    /**
     * 提交订单
     * @private
     * @returns string
     */
    private async onConfirmSubmitOrder(): Promise<any>
    {
        try
        {
            if(this.loading)
            {
                return false;
            }
            this.loading = true;

            let baseItems = [];
            let optionalItems = [];
            if(this.baseItemTypesOut && this.baseItemTypesOut.length>0)
            {
                this.baseItemTypesOut.forEach((item)=>
                {
                    item.items.forEach(d=>
                    {
                        baseItems.push(d.itemId);
                    })
                })
            }

            if(this.optionalItemArr && this.optionalItemArr.length>0)
            {
                this.optionalItemArr.forEach((item)=>
                {
                    item.data.forEach(d=>
                    {
                        optionalItems.push(d.itemId);
                    })
                })
            }

            let data =
            {
                memberServiceItemId: this.serviceItemId,
                memberId: this.selectUserId, // 客户编号
                packageId: this.packageId, // 套餐id
                hospId: this.hospitalId, // 医院编号，外键
                reserveTime: this.selectOrderDate,// 预约日期
                baseItems:  baseItems.join(","), // 固定体检项目编号集合
                optionalItems: optionalItems.join(","), // 可选检查项目编号集合
            };

            let {content: result} = await OrderService.instance.submitOrder(data);

            if(result.data)
            {
                switch(result.data.orderStatus)
                {
                    case 1:
                        {
                            this.onBridgeReady(result.data, result.data.orderId)
                            break;
                        }
                    case 2:
                        {
                            // 无需支付，跳转订单详情
                            this.$router.push({name: "orderDetail", params:{id: result.data.orderId}});
                            break;
                        }
                }
            }

        }
        catch(err)
        {
            Toast(err);
        }
        finally
        {
            this.loading = false;
        }
    }

    /**
     * 付款
     * @private
     * @returns void
     */
    private onBridgeReady(model: any, orderId: string): void
    {
        let data =  model.payRes && model.payRes.parameter && JSON.parse(model.payRes.parameter)
        let self = this;
        WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
                appId: data.appId, // 公众号名称，由商户传入
                timeStamp: data.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: data.nonceStr, // 随机串
                package: data.package, //
                signType: data.signType, // 微信签名方式:
                paySign: data.paySign // 微信签名
            },
            function(res)
            {
                if (res.err_msg === "get_brand_wcpay_request:ok")
                {
                    self.$router.push({name: "orderDetail", params:{id: orderId}});
                    Toast.success("支付成功");
                    // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                }
                else if(res.err_msg === "get_brand_wcpay_request:cancel")
                {
                    // 取消支付
                    self.$router.push({name: "orderDetail", params:{id: orderId}});
                }
                else
                {
                    self.$router.push({name: "orderDetail", params:{id: orderId}});
                    Toast.fail("支付失败");
                }
            }
        );
    }

    /**
     * 显示排期弹框
     * @private
     * @returns void
     */
    private showDateModal(): void
    {
        this.showDate = true;
    }

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
           location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string): void
    {
        Dialog.alert({
            messageAlign: "left",
            confirmButtonColor: "#00d5c1",
            message: remark,
            confirmButtonText: "确定"
        });
    }

}
