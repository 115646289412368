


















































































import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, PackageService } from "src/services";
import { Toast } from "vant";
import EnumUtils from "src/utils/enum-utils";
import { ORG_TYPE } from "src/enums";
import { tools } from "src/utils";
import { Service } from "src/decorator";
import dayjs from "dayjs";

@Component
export default class Home extends Vue
{

    /**
     * 是否在加载中
     * @private
     * @returns boolean
     */
    private isLoading: boolean = false;

    /**
     * 机构类型
     * @private
     * @returns ORG_TYPE
     */
    private ORG_TYPE: ORG_TYPE;

    /**
     * 是否选择城市弹框
     * @private
     * @returns boolean
     */
    private showCity: boolean = false;

    /**
     * 总页数
     * @private
     * @returns number
     */
    private totalPage: number = 0;

    /**
     * 是否上拉加载完成
     * @private
     * @returns boolean
     */
    private isRefreshLoading: boolean = false;

    /**
     * 选中的标签
     * @private
     * @returns Array<string>
     */
    private selectTag: Array<string> = [];

    /**
     * 数据列表
     * @private
     * @returns Array<any>
     */
    private dataList: Array<any> = [];

    /**
     * 标签列表
     * @private
     * @returns Array<string>
     */
    private tagList: Array<string> = [];

    /**
     * 是否刷新
     * @private
     * @returns boolean
     */
    private isRefresh: boolean = false;

    /**
     * 机构类型
     * @private
     * @returns number
     */
    private hospitalType: number = 0;

    /**
     * 搜索关键字
     * @private
     * @returns string
     */
    private filters: any =
    {
        packageId: "",
        contractId: "",
        longitude: "",
        latitude: "",
        cityName:"",
        hospitalType: "",
        isDistance: false, // 是否按距离
        pageIndex: 1 // 当前页码
    };

    /**
     * 是否正在加载中
     * @private
     * @returns boolean
     */
    private loading: boolean = false;

    /**
     * 是否加载完成
     * @private
     * @returns boolean
     */
    private finished: boolean = false;

    /**
     * 套餐id
     * @private
     * @returns string
     */
    private get packageId(): string
    {
        return this.$route.query && (this.$route.query.packageId as string) || "";
    }

    /**
     * 当前位置
     * @private
     * @returns any
     */
    private get location(): any
    {
        return this.$store.getters.location;
    }

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取服务id
     * @private
     * @returns any
     */
    protected get serviceItemId(): any
    {
        return this.$route.query && this.$route.query.serviceItemId;
    }

    /**
     * 获取医院类型
     * @private
     * @returns Array<any>
     */
    private get orgTypeList(): Array<any>
    {
       let orgTypeList = EnumUtils.getEntries(ORG_TYPE).filter(d => d.value !== ORG_TYPE.distance).map(item =>
       {
            return {
                ...item,
                text: item.description
            };
       });
       orgTypeList.unshift({
            name: "",
            alias: "",
            description: "",
            value: 0,
            text: "全部"
       });

       return orgTypeList;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {

        this.filters.packageId = this.packageId;

        if(this.location)
        {
            this.filters.cityName = this.location.city;
            this.filters.latitude = this.location.lat;
            this.filters.longitude = this.location.lng;
        }
        this.getOrgList(this.filters);
    }

    /**
     * 上拉刷新
     * @private
     * @returns void
     */
    private onRefresh(): void
    {
        this.dataList = [];
        this.filters.pageIndex = 1;
        this.finished = false;
        this.isRefresh = true;
        this.getOrgList();

    }

    /*
     * 显示城市组件弹框
     * @private
     * @returns void
     */
    private onShowCityModel(): void
    {
        this.showCity = true;
    }

    /*
     * 跳转详情
     * @private
     * @returns void
     */
    private onDetail(id: string): void
    {
        this.$router.replace({name: "package-detail", query:{id: this.packageId,serviceItemId: this.serviceItemId, hospitalId: id}});
    }

    /*
     * 选择城市
     * @private
     * @returns void
     */
    private onSelectCity(city: string): void
    {
        this.filters.cityName = city;
        this.dataList = [];
        this.filters.pageIndex = 1;
        this.finished = false;
        this.getOrgList();
        this.showCity = false;
    }

    /**
     * 获取数据
     * @private
     * @returns void
     */
    private async getOrgList(params: any = {}): Promise<void>
    {
        this.isLoading = true;

        let filters = Object.assign(this.filters, params);

        if(filters.hospitalType === 0)
        {
            delete filters.hospitalType;
        }

        try
        {
            let {content: result} =  await OrganizationService.instance.getHospitalList(filters);
            
            if(result.data)
            {
                this.totalPage = result.data.totalPage;
                let rows = result.data.rows || [];
                this.dataList = [...this.dataList, ...rows];
                this.loading = false;
                if(result.data.totalPage === this.filters.pageIndex)
                {
                    this.finished = true;
                }
            }

            if(this.isRefresh)
            {
                this.isRefreshLoading = false;
                this.isRefresh = false;
                Toast("刷新成功");
            }

        }
        catch(err)
        {
            Toast(err);
        }
        finally
        {
            this.isLoading = false;
        }
    }

    /**
     * 触发搜索城市事件
     * @private
     * @returns string
     */
    private onSearchCity(): void
    {
        this.dataList = [];
        this.filters.pageIndex = 1;
        this.getOrgList();
    }

    /**
     * 取消搜索城市事件
     * @private
     * @returns void
     */
    private onClearCityRseult(): void
    {
        this.dataList = [];
        this.filters.pageIndex = 1;
        this.getOrgList();
    }

    /**
     * 加载数据
     * @private
     * @returns void
     */
    private onLoadData(): void
    {
        this.filters.pageIndex++;
        this.getOrgList();
    }

    /**
     * 切换tab
     * @private
     * @param {number} bol
     * @returns void
     */
    private onTabChange(bol: boolean): void
    {
        this.filters.isDistance = bol;
        if(bol)
        {
            this.dataList = [];
            this.filters.pageIndex = 1;
            this.getOrgList();
        }

    }

    /**
     * 医院类型切换
     * @private
     * @param {number} type
     * @returns void
     */
    private onOrgTypeChange(type: number): void
    {

        this.dataList = [];
        this.filters.hospitalType = type;
        this.getOrgList();
    }

    /**
     * 显示日历空间日期
     * @private
     * @returns void
     */
    private handlerCalendar(scheduleModel: any): Array<any>
    {
        let schedulesObj = {};
        scheduleModel && scheduleModel.schedules && scheduleModel.schedules.forEach(item=>
        {
            schedulesObj[dayjs(item.startTime).format("YYYY-MM-DD")] = item.personCount;
        })

        let dateList = [];

        for(let i = 0; i<10; i++)
        {
            let current = dayjs().add(i, "day").format("YYYY-MM-DD");
            let num = 0
            if(schedulesObj[current]&&schedulesObj[current]>0)
            {
                num = schedulesObj[current];
            }

            let obj =
            {
                date: current,
                num: num,
                txt: dayjs(current).format("MM.DD")
            };
            dateList.push(obj)
        }
        return dateList
        // this.schedulingData = dateList;
    }
}
